import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, debounceTime, finalize, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { searchResult } from '../types/googleSearch';

// Esto se utiliza para no respoder un atributo vacio en caso de que haya error en la consulta o no haya resultados
export const DEFAULT_RESPONSE_GOOGLE: searchResult = {
  kind: "",
  url: {
    type: "",
    template: ""
  },
  queries: {
    request: [
      {
        title: "",
        totalResults: "",
        searchTerms: "",
        count: 0,
        startIndex: 0,
        inputEncoding: "",
        outputEncoding: "",
        safe: "",
        cx: ""
      }
    ]
  },
  context: {
    title: ""
  },
  searchInformation: {
    searchTime: 0,
    formattedSearchTime: "",
    totalResults: "",
    formattedTotalResults: ""
  },
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class ApiBuscadorGoogleService {

  private apiKey: string = 'AIzaSyAk5j4McvDwgABOseW9NtWJ4U5npeKPHyk'; //Nueva apikey
  private searchEngineId: string = '72f3d3fcd275e4e7e';
  private apiUrl: string = 'https://www.googleapis.com/customsearch/v1';

  private http = inject(HttpClient)
  
  private loadingBool = signal(false);
  private lastSearch = "";

  private searchSubject = new Subject<{ query: string, start: number, sort: string }>();
  private searchResultSubject = new Subject<searchResult>();

  totalResultados = 0;


  constructor() {
    this.searchSubject.pipe(
      debounceTime(500), // Ajusta el tiempo de debounce según sea necesario
      switchMap(params => this.realSearch(params.query, params.start, params.sort))
    ).subscribe({
      next: (response) => this.searchResultSubject.next(response),
      error: (error) => this.searchResultSubject.error(error)
    });
  }

  search(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {
    this.loadingBool.set(true);

    this.searchSubject.next({ query, start, sort });
    return this.searchResultSubject.asObservable();
  }
  
  realSearch(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {

    let params = new HttpParams()
      .set('key', this.apiKey)
      .set('cx', this.searchEngineId)
      .set('q', query)
      .set('start', start.toString());

    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.get<searchResult>(this.apiUrl, { params }).pipe(
      tap(resp => {
        this.lastSearch = query;
        this.totalResultados = Number(resp.searchInformation.totalResults);
      }),
      catchError(this.handleError<searchResult>('getData', DEFAULT_RESPONSE_GOOGLE)),
      finalize(() => this.loadingBool.set(false))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    this.totalResultados = 0;
    return (error: HttpErrorResponse): Observable<T> => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Error del lado del cliente
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Error del lado del servidor
        errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
      }
      // console.error(`${operation} falló: ${errorMessage}`);
      // Retorna un resultado vacío o por defecto para mantener la aplicación funcionando
      return of(result as T);
    };
  }

  isLoading(){
    return this.loadingBool()
  }

}
